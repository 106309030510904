import React, { useEffect, useState } from 'react';
import { Editor } from "@tinymce/tinymce-react";
import { Button, CircularProgress, IconButton, TextField } from '@mui/material';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { CCHAPI } from '../../services/api.services';
import { TiArrowBack } from 'react-icons/ti';
import { BsImageAlt } from 'react-icons/bs';

const UpdateChapter = () => {
    const api = new CCHAPI();
    const navigate = useNavigate()
    const [description, setDescription] = useState("")
    const [chapterimg, setChapterimg] = useState("");
    const [title, setTitle] = useState("");
    const [isSaving, setIsSaving] = useState(false);
    const [isFetched, setIsFetched] = useState(false);
    let { chapterid }: any = useParams();

    const onLoad = async () => {
        setIsFetched(false)
        const response:any = await api.getChapter(chapterid);
        let data = response.data;
        setTitle(data['chapter_title'])
        setDescription(data['chapter_description'])
        setChapterimg(data['chapter_image'])
        setIsFetched(true)
    }

    const onSave = async () => {
        setIsSaving(true)
        // await new Promise(w => setTimeout(w, 1000))
        const formData = new FormData();

        let file:any = document.getElementById("chapterImage")
        file = file.files
        formData.append("title", title);
        formData.append("description", description);
        if(file.length > 0){
            formData.append("is_image", "true");
            formData.append("image", file[0]);
        }
        else{
            formData.append("is_image", "false");
        }
        
        let status: any = await api.updateChapter(chapterid, formData)
        status = status['status']
        console.log(status)

        // if (status) navigate(`/cch/${chapterid}`)
        if (status) onLoad()

        setIsSaving(false)
    }

    const imageUploadChange = async (file:any) => {
        if(file[0]){
            const reader = new FileReader();
            reader.readAsDataURL(file[0]);
            reader.addEventListener("load", () => {
                const uploaded_image = reader.result;
                let element:any = document.getElementById("img-container")
                element.style.backgroundImage = `url(${uploaded_image})`
            })
        }

    }

    useEffect(() => { onLoad() }, [])

    return (
        <div>
            <div className="w-100 d-flex justify-content-between align-items-center mb-2">
                <div>
                    <IconButton onClick={(e) => { navigate(-1) }} className="table-action-button mx-1"><TiArrowBack /></IconButton>
                </div>
                <div className="d-flex justify-content-end align-items-center">
                    {
                        isSaving ? (
                            <div className="px-4">
                                <CircularProgress style={{ color: 'white' }} size={22} />
                            </div>
                        ) : (
                            <Button disabled={!isFetched} onClick={(e) => { onSave() }} className="table-action-button mx-1">Update</Button>
                        )
                    }
                </div>
            </div>
            <div className='d-flex my-4 justify-content-start align-items-center'>
                <div id='img-container' className="img-container my-auto" style={{backgroundImage: `url(${chapterimg})`, color: 'white'}}>
                    {/* <img id='' src={chapterimg} alt="" className="w-100 h-100"/> */}
                    <input onChange={(e)=>{imageUploadChange(e.target.files)}} type="file" id="chapterImage" className="d-none" />
                    {(isFetched) ? (<div className="img-hover-card d-flex justify-content-center align-items-center">
                        <IconButton onClick={(e:any)=>{document.getElementById("chapterImage")!.click()}} style={{ color: 'white', border: '1px solid  rgb(205, 205, 205)', textTransform: 'capitalize' }}><BsImageAlt size={13}/></IconButton>
                    </div>) : null
                    }
                </div>
                <div className="mx-4 w-75">
                    <TextField
                        disabled={!isFetched}
                        className="mx-2 my-2"
                        value={title}
                        onChange={(e: any) => { setTitle(e.target.value) }}
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Title"
                        type="text"
                        fullWidth
                        variant="standard"
                        InputLabelProps={{ style: { color: 'var(--textLightColor)' } }}
                        inputProps={{ style: { color: 'var(--textColor)' } }}
                        style={{ color: 'var(--textColor)' }}
                    />
                    <TextField
                        disabled={!isFetched}
                        className="mx-2 my-2"
                        value={description}
                        onChange={(e: any) => { setDescription(e.target.value) }}
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Description"
                        type="text"
                        fullWidth
                        variant="standard"
                        InputLabelProps={{ style: { color: 'var(--textLightColor)' } }}
                        inputProps={{ style: { color: 'var(--textColor)' } }}
                        style={{ color: 'var(--textColor)' }}
                    />
                </div>
            </div>
        </div>
    );
}

export default UpdateChapter;