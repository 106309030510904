import { Button } from "@mui/material";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import Summary from "../components/summary";
import DataTable from "../components/table";

const Users = () => {

    const columns: any = [
        { field: 'no', headerName: 'No', sortable: true },
        { field: 'username', headerName: 'Username' },
        { field: 'createdat', headerName: 'Created At' },
        { field: 'Last Login', headerName: 'lastlogin' },
        { field: 'actions', headerName: '' },
    ];

    const users: any = []

    useEffect(()=>{
        const sync = async () => {

        }
        sync()
    }, [])

    return (
        <div>
            <div className="summary-section w-100 d-flex flex-wrap border-bottom py-4">
                <Summary name="All" value="0" />
                <Summary name="Suspended" value="0" />
            </div>

            <div className="w-100">
                <DataTable columns={columns} rows={users} />
            </div>
        </div>
    );
}

export default Users;