import { useState, useEffect } from 'react';
import { Button, IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import Summary from "../../components/summary";
import DataTable from "../../components/table";
import { createChapter, openConfirmationMessage } from "../../context/atoms";
import { useRecoilState } from 'recoil';
import { ConfirmationMessage, CreateChapter } from '../../components/modals';
import { MdDelete } from "react-icons/md";
import { CCHAPI } from '../../services/api.services';
import { ExportToCsv } from 'export-to-csv';

const columns = [
    { field: 'no', headerName: 'No', sortable: true },
    { field: 'title', headerName: 'Title' },
    { field: 'desc', headerName: 'Description' },
    { field: 'prayers', headerName: 'Prayers', type: 'number' },
    { field: 'actions', headerName: '' },
];

const ChuoChaSala = () => {
    const api = new CCHAPI();
    const [open, setOpen] = useRecoilState(createChapter);
    const [openConfirmation, setOpenConfirmation] = useRecoilState(openConfirmationMessage);
    const [chapters, setChapters] = useState([]);
    const deleteTitle = "Action Confirmation"
    const [deleteMessage, setDeleteMessage] = useState("")
    const [chapterOnFocus, setChapterOnFocus] = useState("");

    const onLoad = async () => {
        let response = await api.getChapters();
        response = response.data
        let list: any = [];
        response.forEach((chapter: any, index: number) => {
            list.push({
                no: index + 1,
                title: chapter['chapter_title'],
                desc: chapter['chapter_description'],
                prayers: chapter['prayers_count'],
                image: chapter['chapter_image'],
                actions: (
                    <div className="d-flex flex-row justify-content-end align-items-center mx-4 align-self-center mr-auto">
                        <Link to={`/cch/${chapter['id']}`} style={{ textDecoration: 'none' }}><Button className="table-action-button mx-2">View</Button></Link>
                        <Button onClick={(e) => { setChapterOnFocus(chapter['id']); setDeleteMessage(`Deleting ${chapter['chapter_title']}, Deleting this chapter will delete ${chapter['prayers_count']} prayer(s), Click Yes to confirm action or No to decline`); setOpenConfirmation(true); }} variant="outlined" color="error" className="mx-2"><MdDelete size={24} /></Button>
                    </div>
                )
            })
        })
        setChapters(list);
    }

    const chapterCreatedCallback = async () => {
        onLoad()
    }

    useEffect(() => { onLoad() }, [])

    const onDelete = async (status: any) => {
        if (status) {
            let response: any = await api.deleteChapter(chapterOnFocus);
            if (response['status']) await onLoad();
        }
    }

    const DownloadTemplate = async (event: any) => {
        var data:any = [{}]

        const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: false,
            filename: 'Chapters Templete',
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: false,
            headers: ['Title', 'Description'] // <-- Won't work with useKeysAsHeaders present!
        };

        const csvExporter = new ExportToCsv(options);

        csvExporter.generateCsv(data);
    }

    const uploadCSV = async (event: any) => {
        let input = document.getElementById("csvuploadinput")
        input?.addEventListener("change", async (e:any)=>{
            const formData:any = new FormData();
            let file = e.target.files[0]
            formData.append('file', file)
            let response: any = await api.uploadChapters(formData);
            console.log(file)
        })
        input?.click()
    }

    return (
        <div>
            <ConfirmationMessage title={deleteTitle} message={deleteMessage} callback={onDelete} />
            <CreateChapter callback={chapterCreatedCallback} />
            <div className="summary-section w-100 d-flex flex-wrap border-bottom py-0">
                <Summary name="Chapters" value={chapters.length.toString()} />
                <Summary name="Prayers" value={chapters.reduce((prev: number, current: any) => prev + current.prayers, 0).toString()} />
            </div>
            <div className="summary-section w-100 d-flex justify-content-end flex-wrap py-2 my-1">
                <input accept='*.csv, text/csv' type="file" className="d-none" id="csvuploadinput" />
                <Button className="table-action-button mx-2" onClick={DownloadTemplate} >Download Template</Button>
                <Button className="table-action-button mx-2" onClick={uploadCSV} >Upload CSV</Button>
                <Button className="table-action-button mx-2" onClick={(e) => { setOpen(true) }} >Create</Button>
            </div>
            <div className="mt-0 d-flex flex-column align-items-start justify-content-start">
                <DataTable columns={columns} rows={chapters} />
            </div>
        </div>
    );
}

export default ChuoChaSala;