import { MdDashboard, MdCategory } from 'react-icons/md';
import { HiUsers } from 'react-icons/hi';
import Dashboard from '../pages/dashboard';
import Projects from '../pages/projects';
import Users from '../pages/users';
import { FaCross } from 'react-icons/fa';
import ChuoChaSala from '../pages/chuochasala/chuochasala';
import ChuoChaSalaChapter from '../pages/chuochasala/chuochasala-chapter';
import EditPrayer from "../pages/chuochasala/chuochasala-edit-prayer"
import ViewPrayer from "../pages/chuochasala/chuochasala-view-prayer"
import CreatePrayer from '../pages/chuochasala/chuochasala-create-prayer';
import UpdateChapter from '../pages/chuochasala/chuochasala-update-chapter';

const Navigation = () => {
    return [
        {
            name: 'Dashboard',
            link: '/',
            icon: <MdDashboard size={19}/>,
            clickable: true,
            component: Dashboard
        },
        {
            name: 'Projects',
            link: '/projects',
            icon: <MdCategory size={19}/>,
            clickable: true,
            component: Projects
        },
        {
            name: 'Chuochasala',
            link: '/cch',
            icon: <FaCross size={19}/>,
            clickable: false,
            component: ChuoChaSala
        },
        {
            name: 'Chuochasala',
            link: '/cch/:chapterid',
            icon: <FaCross size={19}/>,
            clickable: false,
            component: ChuoChaSalaChapter
        },
        {
            name: 'CreatePrayer',
            link: '/cch/:chapterid/create',
            icon: <FaCross size={19}/>,
            clickable: false,
            component: CreatePrayer
        },
        {
            name: 'CreatePrayer',
            link: '/cch/:chapterid/update',
            icon: <FaCross size={19}/>,
            clickable: false,
            component: UpdateChapter
        },
        {
            name: 'EditPrayer',
            link: '/cch/:chapterid/:prayerid/edit',
            icon: <FaCross size={19}/>,
            clickable: false,
            component: EditPrayer
        },
        {
            name: 'ViewPrayer',
            link: '/cch/:chapterid/:prayerid/view',
            icon: <FaCross size={19}/>,
            clickable: false,
            component: ViewPrayer
        },
        {
            name: 'Users',
            link: '/users',
            icon: <HiUsers size={19}/>,
            clickable: true,
            component: Users
        }
    ]
}
 
export default Navigation;