import { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useRecoilState } from 'recoil';
import { createChapter, openConfirmationMessage } from "../context/atoms";
import { CCHAPI } from '../services/api.services';
import { CircularProgress } from '@mui/material';

export function CreateChapter({ callback }: any) {
  const [open, setOpen] = useRecoilState(createChapter);
  const [file, seFile] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const onCreate = async () => {
    setIsLoading(true)
    const api = new CCHAPI();
    try {
      const formData = new FormData();
      formData.append("title", title);
      formData.append("image", file[0]);
      formData.append("description", description);

      let response = await api.createChapter(formData);

      if(response.status){
        setIsLoading(false)
        setOpen(false);
        callback()
        seFile("")
        setTitle("")
        setDescription("")
      }else{
        // on Error
        setIsLoading(false)
      }

    } catch (e) { }
  };

  return (
    <div>
      <Dialog fullWidth={true} open={open} onClose={handleClose}>
        <DialogTitle>Create New Chapter</DialogTitle>
        <DialogContent>
          <TextField
            onChange={(e: any) => { seFile(e.target.files) }}
            autoFocus
            margin="dense"
            id="name"
            type="File"
            fullWidth
            variant="standard"
          />
          <TextField
            value={title}
            onChange={(e: any) => { setTitle(e.target.value) }}
            autoFocus
            margin="dense"
            id="name"
            label="Title"
            type="text"
            fullWidth
            variant="standard"
          />
          <TextField
            value={description}
            onChange={(e: any) => { setDescription(e.target.value) }}
            autoFocus
            margin="dense"
            id="name"
            label="Description"
            type="text"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          {
            isLoading ? (
              <div className="px-4">
                <CircularProgress style={{ color: 'black' }} size={22} />
              </div>
            ) : (
              <Button onClick={(e) => { onCreate() }}>Create</Button>
            )
          }
        </DialogActions>
      </Dialog>
    </div>
  );
}

export function ConfirmationMessage({ title, message, callback }: any) {
  const [open, setOpen] = useRecoilState(openConfirmationMessage);
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = async () => {
    await callback(false);
    setOpen(false);
  };

  const onConfirm = async () => {
    setIsLoading(true)
    const api = new CCHAPI();
    await callback(true)
    setIsLoading(false)
    setOpen(false);
  };

  return (
    <div>
      <Dialog fullWidth={true} open={open} onClose={handleClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <p>{message}</p>
        </DialogContent>
        <DialogActions>
          {
            isLoading ? (
              <div className="px-4">
                <CircularProgress style={{ color: 'black' }} size={22} />
              </div>
            ) : (
              <Button onClick={(e) => { onConfirm() }}>Yes</Button>
            )
          }
          <Button onClick={handleClose}>No</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
