import React, { useEffect, useState } from 'react';
import { Editor } from "@tinymce/tinymce-react";
import { Button, CircularProgress, IconButton, TextField } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { CCHAPI } from '../../services/api.services';
import { TiArrowBack } from 'react-icons/ti';
const CreatePrayer = () => {
    const api = new CCHAPI();
    const [contents, setContents] = useState("")
    const [title, setTitle] = useState("");
    const [isSaving, setIsSaving] = useState(false);
    const navigate = useNavigate()
    let { chapterid }: any = useParams();

    const onLoad = async () => {
        console.log(chapterid)
    }

    useEffect(() => { onLoad() }, [])

    const handleChange = async (content: any, editor: any) => {
        setContents(content)
    }

    const onCancel = async () => {
        setContents("");
        setTitle("");
    }

    const onSave = async () => {
        setIsSaving(true)
        // await new Promise(w => setTimeout(w, 1000))
        const formData = new FormData();

        formData.append("title", title);
        formData.append("content", contents);

        let status = await api.createPrayer(chapterid, formData)
        status = status['status']

        if (status) navigate(`/cch/${chapterid}`)
        console.log(status)
        setIsSaving(false)
    }

    const pasteContent = async () => {
        navigator.clipboard.readText().then(text => {
            setContents(text)
        })
            .catch(err => {
                alert('Failed to read clipboard contents');
            })
    }

    return (
        <div>
            <div className="w-100 d-flex justify-content-between align-items-center mb-2">
                <div>
                    <IconButton onClick={(e) => { navigate(-1)  }} className="mx-1" style={{ color: 'white', border: '1px solid  rgb(52, 52, 52)', textTransform: 'capitalize' }}><TiArrowBack /></IconButton>
                </div>
                <div className="d-flex justify-content-end align-items-center">
                    <Button onClick={(e) => { pasteContent() }} style={{ color: 'white', border: '1px solid  rgb(52, 52, 52)', textTransform: 'capitalize' }}>Paste</Button>
                    <Button onClick={(e) => { onCancel() }} className="mx-1" style={{ color: 'white', border: '1px solid  rgb(52, 52, 52)', textTransform: 'capitalize' }}>Clear</Button>
                    {
                        isSaving ? (
                            <div className="px-4">
                                <CircularProgress style={{ color: 'white' }} size={22} />
                            </div>
                        ) : (
                            <Button onClick={(e) => { onSave() }} className="mx-1" style={{ color: 'white', border: '1px solid  rgb(52, 52, 52)', textTransform: 'capitalize' }}>Save</Button>
                        )
                    }
                </div>
            </div>
            <TextField
                className="mx-2 my-2"
                value={title}
                onChange={(e: any) => { setTitle(e.target.value) }}
                autoFocus
                margin="dense"
                id="name"
                label="Title"
                type="text"
                fullWidth
                variant="standard"
                InputLabelProps={{ style: { color: 'rgb(160,160,160)' } }}
                inputProps={{ style: { color: 'white' } }}
                style={{ color: 'white' }}
            />
            <Editor id='chCont'
                init={{
                    height: 450,
                    menubar: false,
                }}
                onEditorChange={handleChange}
                value={contents}
            />
        </div>
    );
}

export default CreatePrayer;