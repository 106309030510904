import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import DataTable from "../components/table";

const columns: any = [
    { field: 'no', headerName: 'No', sortable: true },
    { field: 'title', headerName: 'Title' },
    { field: 'managedBy', headerName: 'Managed By' },
    { field: 'createdAt', headerName: 'Created At' },
    { field: 'actions', headerName: 'Actions' },
];

const prayers: any = [
    {
        no: '1',
        title: 'Chuo Cha Sala',
        managedBy: 'Fundo Mkambat',
        createdAt: '01 Oct 2022',
        actions: (
            <div className="d-flex flex-row justify-content-end align-items-center mx-4 align-self-center mr-auto">
                <Link to="/cch" style={{ textDecoration: 'none' }}><Button className="table-action-button mx-2">Manage</Button></Link>
                <Link to="" style={{ textDecoration: 'none' }}><Button className="table-action-button mx-2">Audit Trail</Button></Link>
            </div>
        ),
    }
]

const Projects = () => {
    return (
        <div>
            <div className="mt-4 d-flex flex-column align-items-start justify-content-start">
                <DataTable columns={columns} rows={prayers} />
            </div>
        </div>
    );
}

export default Projects;