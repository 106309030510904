import { atom } from 'recoil';

export const NavExpandedState = atom({
    key: 'navsizestate',
    default: true,
})

export const ActiveNavLink = atom({
    key: 'activenavlink',
    default: window.location.pathname,
})

export const logedin = atom({
    key: 'islogedin',
    default: sessionStorage.getItem("authenticated") == 'true',
})
export const createChapter = atom({
    key: 'createchapter',
    default: false,
})
export const openConfirmationMessage = atom({
    key: 'openconfirmationmessage',
    default: false,
})
export const thememode = atom({
    key: 'thememode',
    default: 'light',
})