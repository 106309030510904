import React, { useEffect, useState } from 'react';
import { Editor } from "@tinymce/tinymce-react";
import { Button, CircularProgress, IconButton, TextField } from '@mui/material';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { CCHAPI } from '../../services/api.services';
import { TiArrowBack } from 'react-icons/ti';

const ViewPrayer = () => {
    const api = new CCHAPI();
    const navigate = useNavigate()
    const [contents, setContents] = useState("")
    const [title, setTitle] = useState("");
    let { chapterid, prayerid }: any = useParams();

    const onLoad = async () => {
        const response = await api.getPrayer(chapterid, prayerid);
        let data = response.data;
        setTitle(data['prayer_title'])
        setContents(data['prayer_content'])
    }

    useEffect(() => { onLoad() }, [])

    return (
        <div>
            <div className="w-100 d-flex justify-content-between align-items-center mb-2">
                <div>
                    <IconButton onClick={(e) => { navigate(-1) }} className="mx-1" style={{ color: 'white', border: '1px solid  rgb(52, 52, 52)', textTransform: 'capitalize' }}><TiArrowBack /></IconButton>
                </div>
                <div className="d-flex justify-content-end align-items-center"></div>
            </div>
            <div className="d-flex">
                <p>Title</p>
                <p>{title}</p>
            </div>
            <Editor disabled={true} id='chCont'
                init={{
                    height: 450,
                    menubar: false,
                }}
                value={contents}
            />
        </div>
    );
}

export default ViewPrayer;