import { useState, useEffect } from "react";
import { Button, IconButton } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import DataTable from "../../components/table";
import { CCHAPI } from '../../services/api.services';
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { openConfirmationMessage } from "../../context/atoms"
import { ConfirmationMessage } from "../../components/modals";
import { GiPrayerBeads } from "react-icons/gi";
import Summary from "../../components/summary";
import { TiArrowBack } from "react-icons/ti";

const columns = [
    { field: 'no', headerName: 'No', sortable: true },
    { field: 'title', headerName: 'Title' },
    { field: 'createdAt', headerName: 'Created At' },
    { field: 'updatedAt', headerName: 'Updated At' },
    { field: 'actions', headerName: '' },
];

const ChuoChaSalaChapter = () => {
    const navigate = useNavigate();
    const api = new CCHAPI();
    let { chapterid }: any = useParams();
    const [prayers, setPrayers] = useState([]);
    const [prayerOnFocus, setPrayerOnFocus] = useState("");
    const [currentChapter, setCurrentChapter]: any = useState({});
    const [openConfirmation, setOpenConfirmation] = useRecoilState(openConfirmationMessage);

    const deleteTitle = "Action Confirmation"
    const [deleteMessage, setDeleteMessage] = useState("")

    const onLoad = async () => {
        let response: any = await api.getPrayers(chapterid);
        setCurrentChapter(response.chapter)
        response = response.data
        let list: any = [];
        response.forEach((prayer: any, index: number) => {
            list.push({
                no: index + 1,
                title: prayer['prayer_title'],
                createdAt: prayer['created_at'],
                updatedAt: prayer['updated_at'],
                actions: (
                    <div className="d-flex flex-row justify-content-end align-items-center mx-4 align-self-center mr-auto">
                        <Link to={`${prayer['id']}/view`} style={{ textDecoration: 'none' }}><Button className="table-action-button mx-2">View</Button></Link>
                        <Link to={`${prayer['id']}/edit`} style={{ textDecoration: 'none' }}><Button className="table-action-button mx-2">Edit</Button></Link>
                        <Button onClick={(e) => { setPrayerOnFocus(prayer['id']); setDeleteMessage(`Deleting ${prayer['prayer_title']}. Are you sure you want to proceed ?`); setOpenConfirmation(true); }} className="table-action-button mx-2">Delete</Button>
                    </div>
                )
            })
        })
        setPrayers(list);
    }

    const onDelete = async (status: any) => {
        if (status) {
            let response = await api.deletePrayer(chapterid, prayerOnFocus);
            if (response.status) await onLoad();
        }
    }

    useEffect(() => { onLoad() }, [])

    return (
        <div>
            <ConfirmationMessage title={deleteTitle} message={deleteMessage} callback={onDelete} />
            <div className="summary-section w-100 d-flex justify-content-between flex-wrap my-1">
                <div>
                    <IconButton onClick={(e) => { navigate(-1) }} className="table-action-button mx-1"><TiArrowBack /></IconButton>
                </div>
                <div className="d-flex justify-content-end align-items-center">
                    <Link to="update/" style={{ textDecoration: 'none' }}><Button className="table-action-button mx-2">Update Chapter</Button></Link>
                    <Link to="create/" style={{ textDecoration: 'none' }}><Button className="table-action-button mx-2">Create New Prayer</Button></Link>
                </div>
            </div>
            <div className="d-flex">
                <div className="summary-section w-100 d-flex flex-wrap border-bottom py-4">
                    <div id='img-container' className="img-container my-auto" style={{backgroundImage: `url(${currentChapter["chapter_image"]})`, color: 'white'}}>
                        {/* <img src={currentChapter["chapter_image"]} alt="" className="h-100"/> */}
                    </div>
                    <Summary name="Title" value={currentChapter["chapter_title"]} />
                    <Summary name="Prayers" value={prayers.length.toString()} />
                </div>
            </div>
            <div className="mt-4 d-flex flex-column align-items-start justify-content-start">
                <DataTable columns={columns} rows={prayers} />
            </div>
        </div>
    );
}

export default ChuoChaSalaChapter;