import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useRecoilState } from 'recoil';
import { thememode } from '../context/atoms';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        // backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        fontWeight: 'bolder'
    },
    [`&.${tableCellClasses.body}`]: {
        // backgroundColor: theme.palette.primary,
        color: theme.palette.common.white,
        fontSize: 14,
    },
}));

// const StyledTableRow = styled(TableRow)(({ theme }) => ({
//     '&:nth-of-type(odd)': {
//         backgroundColor: theme.palette.action.hover,    },
//     // hide last border
//     '&:last-child td, &:last-child th': {
//         border: 0,
//     },
// }));

export default function DataTable({ rows, columns }: any) {
    const [themeMode, setThemeMode] = useRecoilState(thememode);

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            // backgroundColor: theme.palette.common.black,
            color: (themeMode == 'dark') ? theme.palette.common.white : theme.palette.common.black,
            fontWeight: 'bolder'
        },
        [`&.${tableCellClasses.body}`]: {
            // backgroundColor: theme.palette.primary,
            color: (themeMode == 'dark') ? theme.palette.common.white : theme.palette.common.black,
            fontSize: 14,
        },
    }));

    return (
        <TableContainer className="table-container" component={Paper}>
            <Table sx={{ minWidth: 700 }} className="table" aria-label="customized table">
                <TableHead>
                    <TableRow>
                        {
                            columns && columns.map((column:any, index:number) => {
                                return (<StyledTableCell key={index}>{column.headerName}</StyledTableCell>);
                            })
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        rows.map((row:any, index:number) => (
                            <TableRow key={row.name}>
                                {
                                    columns && columns.map((column:any, _index:number) => {
                                        return (<StyledTableCell key={_index}>{row[column.field]}</StyledTableCell>)
                                    })
                                }
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
}
