import axios from "axios";

class Defaults {
    debug: boolean = false;

    url: string = this.debug ? "http://127.0.0.1:8000" : "https://api.nexent.dev"; // should not end with foreward slash

    headers: any = {
        "Content-Type": "multipart/form-data",
        "Accept": "application/json",
    }
}

export default class API extends Defaults{

    constructor() {
        super();
    }

    async login(username: string, password: string) {
        const data = new FormData();
        let response: any;
        data.append("username", username);
        data.append("password", password);
        try {
            response = await axios.post(`${this.url}/auth/token/`,
                data,
                {
                    headers: this.headers,
                }
            )

            let is_valid = Object.keys(response.data).includes('access')
            
            if(is_valid){
                sessionStorage.setItem("authenticated", 'true');
                sessionStorage.setItem("access_token", response.data['access']);
                sessionStorage.setItem("refresh_token", response.data['refresh']);
            }

        } catch (e) { }

        return response.data;
    }

    async resetPassword(email: string) {
        let response = await axios.post(`${this.url}/auth/login/`,
            {
                username: 'Joe',
                password: '01011000'
            },
            {
                headers: this.headers,
            })
        return response.data;
    }

    logout() {
        sessionStorage.removeItem("authenticated");
    }
}

export class CCHAPI extends Defaults{
    constructor() {
        super();
    }

    async getChapters(){
        const response = await axios.get(`${this.url}/cch/getchapters`);
        let data = response.data.data
        for(let i = 0; i < data.length; i += 1){
            let chapter:any = data[i]
            chapter['chapter_image'] = this.url + "/" + chapter['chapter_image']
        }
        return response.data;
    }

    async getChapter(chapterId:string){
        const response = await axios.get(`${this.url}/cch/getchapter/${chapterId}`);
        let data:any = response.data
        data['data']['chapter_image'] = this.url + "/" + data['data']['chapter_image']
        return data;
    }
    
    async createChapter(formData:any){
        const response = await axios.post(`${this.url}/cch/createchapter`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });
        return response.data;
    }

    async updateChapter(chapterId:string, formData:any){
        const response = await axios.put(`${this.url}/cch/updatechapter/${chapterId}`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });
        return response.data;
    }
    
    async deleteChapter(chapterId:string){
        const response = await axios.delete(`${this.url}/cch/deletechapter/${chapterId}`, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });
        return response.data;
    }
    
    ///////////////////////////////////////////////////////

    async getPrayers(chapterId:string){
        const response = await axios.get(`${this.url}/cch/getprayers/${chapterId}`);
        let data = response.data
        data['chapter']['chapter_image'] = this.url + "/" + data['chapter']['chapter_image']
        return data;
    }

    async getPrayer(chapterId:string, prayerId:string){
        const response = await axios.get(`${this.url}/cch/getprayer/${chapterId}/${prayerId}`, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });
        return response.data;
    }

    async createPrayer(chapterId:string, formData:any){
        const response = await axios.post(`${this.url}/cch/createprayer/${chapterId}`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });
        return response.data;
    }

    async updatePrayer(prayerId:string, formData:any){
        const response = await axios.put(`${this.url}/cch/updateprayer/${prayerId}`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });
        return response.data;
    }

    async deletePrayer(chapterId:string, prayerId:string){
        const response = await axios.delete(`${this.url}/cch/deleteprayer/${chapterId}/${prayerId}`, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });
        return response.data;
    }

    ///////////////////////////////////////////////////////

    async uploadChapters(formData:string){
        const response = await axios.post(`${this.url}/cch/uploadchapters/`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });
        let data = response.data
        return data;
    }
}