import { Button, CircularProgress, TextField } from "@mui/material";
import { Link } from "react-router-dom";
import { useState } from 'react';
import API from "../services/api.services";
import { RecoilRoot, useRecoilState } from 'recoil';
import { logedin } from '../context/atoms';
const Login = () => {

    const api = new API();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isAuthenticated, setLogedIn] = useRecoilState(logedin);
    const [isValid, setIsValid] = useState(false);


    const submit = async (form: any) => {
        form.preventDefault();
        setIsLoading(true);
        setIsValid(true);
        const data: any = { username: username, password: password }
        const response = await api.login(username, password).catch((e) => { setIsLoading(false) })
        setIsLoading(false)
        // if(response.status) setLogedIn(response.status)
        if (response == undefined) {
            setIsValid(false)
        } else { 
            setIsValid(Object.keys(response).includes('access'))
            setLogedIn(Object.keys(response).includes('access'))
         }
    }

    return (
        <div className="d-flex flex-column">
            {!isValid && (<div className="alert alert-warning" style={{backgroundColor: '#fff3cd45', color: '#ffdf81', borderColor: '#ffecb599'}}>Invalid Credentials</div>)}
            <form onSubmit={submit} className={`form-container p-4 ${(isValid) ? "success" : "error"}`} action="">
                <div className="label text-light fw-bolder fs-5 text-uppercase">Login</div>
                <div className="containers d-flex flex-column align-items-center justify-content-center h-75">
                    <TextField value={username} onChange={(e) => { setUsername(e.target.value) }} label="Username" InputLabelProps={{ style: { color: 'rgb(100,100,100)' } }} inputProps={{ style: { fontFamily: 'Arial', color: 'white', borderColor: 'white' } }} style={{ color: 'white' }} variant="filled" type="text" className="w-100 my-2" />
                    <TextField value={password} onChange={(e) => { setPassword(e.target.value) }} label="Password" InputLabelProps={{ style: { color: 'rgb(100,100,100)' } }} inputProps={{ style: { fontFamily: 'Arial', color: 'white', borderColor: 'white' } }} style={{ color: 'white' }} variant="filled" type="password" className="w-100 my-2" />
                    <Button disabled={isLoading} type="submit" className="w-100" style={{ color: 'white', textTransform: 'capitalize' }} >
                        {isLoading ? (<CircularProgress style={{ color: 'white' }} size={25} />) : "Login"}
                    </Button>
                    <Link to="/" className="align-self-start">Dont remember you password ?</Link>
                </div>
            </form>
        </div>
    );
}

export default Login;