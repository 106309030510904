import React, { useEffect, useState } from 'react';
import { Editor } from "@tinymce/tinymce-react";
import { Button, CircularProgress, IconButton, TextField } from '@mui/material';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { CCHAPI } from '../../services/api.services';
import { TiArrowBack } from 'react-icons/ti';

const EditPrayer = () => {
    const api = new CCHAPI();
    const navigate = useNavigate()
    const [contents, setContents] = useState("")
    const [title, setTitle] = useState("");
    const [isSaving, setIsSaving] = useState(false);
    const [isFetched, setIsFetched] = useState(false);
    let { chapterid, prayerid }: any = useParams();


    const handleChange = async (content: any, editor: any) => {
        setContents(content)
    }

    const onClear = async () => {
        setContents("");
        setTitle("");
    }

    const onLoad = async () => {
        setIsFetched(false)
        const response = await api.getPrayer(chapterid, prayerid);
        let data = response.data;
        setTitle(data['prayer_title'])
        setContents(data['prayer_content'])
        setIsFetched(true)
    }

    const onSave = async () => {
        setIsSaving(true)
        // await new Promise(w => setTimeout(w, 1000))
        const formData = new FormData();

        formData.append("title", title);
        formData.append("content", contents);

        let status: any = await api.updatePrayer(prayerid, formData)
        status = status['status']

        // if (status) navigate(`/cch/${chapterid}`)
        if (status) onLoad()

        setIsSaving(false)
    }

    useEffect(() => { onLoad() }, [])

    return (
        <div>
            <div className="w-100 d-flex justify-content-between align-items-center mb-2">
                <div>
                    <IconButton onClick={(e) => { navigate(-1) }} className="table-action-button mx-1"><TiArrowBack /></IconButton>
                </div>
                <div className="d-flex justify-content-end align-items-center">
                    <Button
                    disabled={!isFetched}
                    onClick={(e) => { onClear() }}
                    className="table-action-button mx-1">
                        Clear
                    </Button>
                    {
                        isSaving ? (
                            <div className="px-4">
                                <CircularProgress style={{ color: 'var(--iconColor)' }} size={22} />
                            </div>
                        ) : (
                            <Button
                            disabled={!isFetched}
                            onClick={(e) => { onSave() }}
                            className="table-action-button mx-1">
                                Update
                            </Button>
                        )
                    }
                </div>
            </div>
            <TextField
                disabled={!isFetched}
                className="mx-2 my-2"
                value={title}
                onChange={(e: any) => { setTitle(e.target.value) }}
                autoFocus
                margin="dense"
                id="name"
                label="Title"
                type="text"
                fullWidth
                variant="standard"
                InputLabelProps={{ style: { color: 'var(--textLightColor)' } }}
                inputProps={{ style: { color: 'var(--textColor)' } }}
                style={{ color: 'var(--textColor)' }}
            />
            <Editor id='chCont'
                init={{
                    height: 450,
                    menubar: false,
                }}
                disabled={!isFetched}
                onEditorChange={handleChange}
                value={contents}
            />
        </div>
    );
}

export default EditPrayer;